/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/jquery-migrate@3.0.1/dist/jquery-migrate.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/magnific-popup@1.2.0/dist/jquery.magnific-popup.min.js
 * - /npm/isotope-layout@3.0.6/dist/isotope.pkgd.min.js
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 * - /npm/slider-pro@1.6.2/dist/js/jquery.sliderPro.min.js
 * - /npm/sticky-kit@1.1.3/dist/sticky-kit.min.js
 * - /npm/imagesloaded@4.1.4/imagesloaded.pkgd.min.js
 * - /npm/animejs@2.2.0/anime.min.js
 * - /npm/guillotine@1.3.1/js/jquery.guillotine.min.js
 * - /npm/slidebars@2.0.2/dist/slidebars.min.js
 * - /npm/lightgallery@1.6.5/dist/js/lightgallery-all.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
